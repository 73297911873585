import './App.css';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Added useLocation
import FullApp from './components/FullApp';
import Navbar from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx'; 
import AboutUs from './components/Body/AboutUs.jsx';
import Pricing from './components/WeApply/Pricing/Pricing.jsx';
import Calculator from './components/Calculator/Calculator.jsx';
import OpenJobs from './components/OpenJobs/OpenJobs.jsx';
import FAQ from './components/WeApply/FAQ/FAQ.jsx'

function App() {
  // Initialize Google Analytics with your tracking ID
  ReactGA.initialize('G-4FXN8SWRQR');

  // Custom hook to handle location and tracking
  const LocationListener = () => {
    const location = useLocation();

    useEffect(() => {
      // Track page view on route change
      ReactGA.send({ hitType: "pageview", page: location.pathname });

      // Custom route tracking for /pricing, /calculator, and /openjobs
      if (location.pathname === '/pricing') {
        ReactGA.event({
          category: 'Navigation',
          action: 'Visited Pricing Page',
          label: 'Pricing'
        });
      } else if (location.pathname === '/calculator') {
        ReactGA.event({
          category: 'Navigation',
          action: 'Visited Calculator Page',
          label: 'Calculator'
        });
      } else if (location.pathname === '/openjobs') {
        ReactGA.event({
          category: 'Navigation',
          action: 'Visited Open Jobs Page',
          label: 'Open Jobs'
        });
      }

      // Scroll depth tracking logic
      let hasTracked25 = false;
      let hasTracked50 = false;
      let hasTracked75 = false;
      let hasTracked100 = false;

      const handleScroll = () => {
        const scrollDepth = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;

        if (scrollDepth >= 25 && !hasTracked25) {
          ReactGA.event({
            category: 'Engagement',
            action: 'Scrolled 25%',
            label: 'User Engagement'
          });
          hasTracked25 = true; // Prevents firing again for 25%
        }

        if (scrollDepth >= 50 && !hasTracked50) {
          ReactGA.event({
            category: 'Engagement',
            action: 'Scrolled 50%',
            label: 'User Engagement'
          });
          hasTracked50 = true; // Prevents firing again for 50%
        }

        if (scrollDepth >= 75 && !hasTracked75) {
          ReactGA.event({
            category: 'Engagement',
            action: 'Scrolled 75%',
            label: 'User Engagement'
          });
          hasTracked75 = true; // Prevents firing again for 75%
        }

        if (scrollDepth >= 100 && !hasTracked100) {
          ReactGA.event({
            category: 'Engagement',
            action: 'Scrolled 100%',
            label: 'User Engagement'
          });
          hasTracked100 = true; // Prevents firing again for 100%
          window.removeEventListener('scroll', handleScroll); 
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [location]); // Effect is triggered whenever the location changes

    return null; // This component doesn't render anything
  };

  return (
    <Router>
      <LocationListener /> {/* This will handle route change and scroll tracking */}
      <div >
        <div className="App  top-0 left-0 w-full bg-white shadow-md z-50">
        <Navbar />
        </div>
        <div className="relative ">
          <Routes>
            <Route path="/" element={<FullApp />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/openjobs" element={<OpenJobs />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;