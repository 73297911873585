import spacebanner from '../../../assets/spacebanner.jpeg'
import Spline from "@splinetool/react-spline";
import { Link } from 'react-scroll';
import { Parallax, ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import React from "react";
import { FlipWords } from "../../ui/flip-words";
import { RainbowButton } from '../../ui/pulsatingButton.tsx';
import "./ThreeBoxBanner.css"
import WordFadeIn from "../../ui/worldFadeIn.tsx"





const links = [
  { name: 'Open roles', href: '#' },
  { name: 'Internship program', href: '#' },
  { name: 'Our values', href: '#' },

];
const stats = [
  { name: "Your LinkedIn will be reviewed to ensure that you have a profile that attracts recruiters and showcases your expertise.", value: 'LinkedIn Optimization' },
  { name: 'We create tailored, ATS-friendly resumes that highlight your strengths and align perfectly with the roles you’re targeting.', value: 'Resume Building' },
  { name: "We search and apply for the best jobs that fit your profile in the market. No fillers, only quality opportunities.", value: 'Job Search and Applications' },
];

export default function ThreeBoxBanner() {

  const words = ["better", "W323", "beautiful", "modern"];

  return (
    <div className=''>
    <ParallaxProvider>
      <Parallax speed={-0}>
        <div className="relative isolate overflow-hidden bg-[#FFF]  pb-24  sm:pb-24 py-8">

        <img
          src={spacebanner}
          alt="spacebanner"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-[0%]"
          
          ></img>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">

      <div className="relative mx-auto max-w-5xl text-center pt-16">
          <WordFadeIn words="It's simple -  you sit back while we find, apply, and follow up on jobs for you." />
        <div className='text-3xl pt-12'>
          Our service includes:
        </div>
          {/*<h2 className="text-2xl font-bold tracking-tight text-black sm:text-3xl">
            Your Job Applications, Resume, and LinkedIn - <span className="text-rockethire-orange">All in One </span> Seamless Service.
            <span className="text-rockethire-orange"> </span> 

          </h2> */}

        </div>
        
        <dl className="mt-4 grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-2 lg:grid-cols-3 pb-16">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col items-center text-center duration-300 transform bg-[#ffffff69] border rounded-xl p-4 shadow-xl border-gray-800 hover:-translate-y-2 hover:shadow-2xl">
                <dd className="text-2xl font-semibold leading-9 tracking-tight text-rockethire-orange mb-2">{stat.value}</dd>
                <dt className="text-base leading-7 text-black">{stat.name}</dt>
              </div>
            ))}
          </dl>



        <div className="mx-auto max-w-2xl lg:max-w-none text-center">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:justify-center lg:gap-x-10">
  

          <a
            href="https://calendly.com/rockethire-coffee/we-apply?"
            className="block  text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/*}
            <button className="interactive-button w-full max-w-xl mx-auto py-4 bg-rockethire-orange rounded-xl border-[#ee595900] hover:border-[#d3b34c] border-[2px] hover:border-[2px] text-lg sm:text-xl lg:text-2xl relative overflow-hidden text-white">
              <span class="default-text block">Schedule Your Free Consultation Now</span>
              <span class="hover-text block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300">Let's find your next job!</span>
              <div class="fire-effect"></div>
            </button>    */}
            {/*
            <button className="group/button relative overflow-hidden rounded-md border border-red-500/20 bg-[#cebebe] px-4 py-1 text-xl font-medium text-red-500 transition-all duration-150 hover:border-red-500 active:scale-95">
              <span className="absolute bottom-0 left-0 z-0 h-0 w-full bg-gradient-to-t from-red-600 to-red-500 transition-all duration-500 group-hover/button:h-full" />
              <span className="relative z-10 transition-all duration-500 group-hover/button:text-white">
                Schedule Your Free Consultation Now
              </span>
            </button>
             
            <RainbowButton className='text-white'>Schedule Your Free Consultation Now</RainbowButton>
            */} 

            <a href="https://calendly.com/rockethire-coffee/we-apply?" target="_blank" class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-rockethire-orange transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-rockethire-orange group-hover:h-full"></span>
                <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Schedule Your Free Consultation Now</span>
            </a>


          </a>

            
          </div>

        </div>
      </div>
    </div>
    </Parallax>
    </ParallaxProvider>
    </div>
  );
}